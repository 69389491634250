import { template as template_0525437bb17042b1b8b68787777f6fee } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
const GeneratedInviteLink = template_0525437bb17042b1b8b68787777f6fee(`
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GeneratedInviteLink;
