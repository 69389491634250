import { template as template_fb3da8b1c3fe423fbc9c1a9a5b50b9a6 } from "@ember/template-compiler";
const FKText = template_fb3da8b1c3fe423fbc9c1a9a5b50b9a6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
