import { template as template_dd2e450ed52b43729c7c75740f09f024 } from "@ember/template-compiler";
const FKControlMenuContainer = template_dd2e450ed52b43729c7c75740f09f024(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
