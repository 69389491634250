import { template as template_10c8a4941d49465692fd64575edad5b2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_10c8a4941d49465692fd64575edad5b2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
