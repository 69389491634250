import { template as template_2d73045d615845368782cce331857140 } from "@ember/template-compiler";
const WelcomeHeader = template_2d73045d615845368782cce331857140(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
