import { template as template_86d3897442db4872a83e380924a3b318 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_86d3897442db4872a83e380924a3b318(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
